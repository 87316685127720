import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_STORES } from '@wix/app-definition-ids';

import { log } from '../../../../utils/monitoring';
import { APP_TOKEN, MENU_IDS } from '../../../constants';
import { createLoginIconsMenu, createLoginMenu, updatePagePathInMenuItems } from '../../../wrappers/menus';
import { addLoginButton } from './login-bar';

export const addLoginMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const isStoresInstallation = options.origin.info?.appDefinitionId === WIX_STORES;
  const headerRef = await editorSDK.siteSegments.getHeader(APP_TOKEN);

  if (!headerRef) {
    // site might have no header
    return;
  }

  await Promise.all([createLoginMenu(editorSDK), createLoginIconsMenu(editorSDK)]);

  try {
    await addLoginButton(editorSDK, headerRef, isStoresInstallation);
  } catch (e) {
    log('Failed to add login button', { extra: { error: (e as Error).toString() } });
  }
};

export const addMembersSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: MENU_IDS.SUB_MENU_ID,
  });
};

export const updateMemberPagePathInMenus = async (
  editorSDK: EditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  return Promise.all([
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_MENU_ID, currentPageUriSEO, newPageUriSEO }),
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.SUB_MENU_ID, currentPageUriSEO, newPageUriSEO }),
    updatePagePathInMenuItems({ editorSDK, menuId: MENU_IDS.LOGIN_ICONS_MENU_ID, currentPageUriSEO, newPageUriSEO }),
  ]);
};
